<template>
  <div class="ClippingPrintAreaSelectFile">
    <div class="img-holder">
      <img ref="image" :src="file" alt="">
    </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs/dist/cropper.esm'

export default {
  props: {
    file: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    cropData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      $cropper: null
    }
  },
  mounted () {
    this.initCropper()
  },
  beforeDestroy () {
    this.$cropper.destroy()
  },
  methods: {
    initCropper () {
      const vm = this
      const cropperInstance = new Cropper(this.$refs.image, {
        rotatable: false,
        autoCropArea: 1,
        viewMode: 2,
        data: this.cropData,
        zoomable: false,
        zoomOnWheel: false,
        zoomOnTouch: false,
        cropend (e) {
          const cropData = cropperInstance.getData(true)
          const imageData = cropperInstance.getImageData()
          vm.$emit('crop', { index: vm.index, cropData, imageData })
        }
      })
      this.$cropper = cropperInstance
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.ClippingPrintAreaSelectFile {
  max-height: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  .img-holder, .img-holder > img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
