<template>
  <el-select
    v-model="computedValue"
    v-loading="isLoading"
    :remote="shouldSearch"
    :remote-method="__search"
    :placeholder="$t('pages.coverage.clipping_edit.select_media_outlet')"
    filterable
    name="media_outlet"
    popper-class="mediaOutletModal"
    :disabled="disabled"
  >
    <el-option
      v-for="mediaOutlet in itemsToLoopOver"
      :key="mediaOutlet.id"
      :label="mediaOutlet.name"
      :value="mediaOutlet.id"
    >
      <p class="is-flex is-aligned-middle">
        <country-flag
          v-if="mediaOutlet.country && mediaOutlet.country.data"
          :country="mediaOutlet.country.data.iso_alpha2"
        />
        {{ mediaOutlet.name }}
      </p>
    </el-option>
  </el-select>
</template>

<script>
import _orderBy from 'lodash/orderBy'
import { remoteSelectMixin } from '@hypefactors/shared/js/mixins/remoteSelectMixin'

/**
 * @module ClippingMediaOutletsPicker
 */
export default {
  name: 'ClippingMediaOutletsPicker',

  extends: remoteSelectMixin,

  props: {
    /**
     * @type {string} Media type - (print, web, radio etc)
     */
    type: {
      type: String,
      required: true
    },

    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    },

    hasCountry: {
      type: Boolean,
      required: false,
      default: () => null
    },

    hasPublisher: {
      type: Boolean,
      required: false,
      default: () => null
    }
  },

  methods: {
    search (payload) {
      payload.params = {
        ...payload.params,
        type: this.type,
        'has-country': this.hasCountry,
        'has-publisher': this.hasPublisher,
        sort: ['name-length'],
        limit: 25,
        include: ['country']
      }

      return this.$api.get('media-outlets', payload)
        .then(response => response.data.data)
        .then(data => {
          if (this.value) {
            const hasOutlet = data.find(outlet => outlet.id === this.value)

            if (!hasOutlet) {
              return this.$api.get(`/media-outlets/${this.value}`, {
                params: {
                  include: ['country']
                }
              }).then(response => {
                data.push(response.data.data)

                return data
              })
            }
          }

          return data
        })
        .then(data => {
          return _orderBy(data, 'name')
        })
    }
  }
}
</script>
