<template>
  <el-select
    v-model="computedValue"
    v-loading="isLoading"
    :remote-method="__search"
    :placeholder="$t('pages.coverage.clipping_edit.select_country')"
    filterable
    name="country"
    popper-class="countryModal"
  >
    <el-option
      v-for="country in itemsToLoopOver"
      :key="country.id"
      :label="country.name"
      :value="country.iso_alpha2"
    >
      <p class="is-flex is-aligned-middle">
        <country-flag :country="country.iso_alpha2" />
        {{ country.name }}
      </p>
    </el-option>
  </el-select>
</template>

<script>
import _orderBy from 'lodash/orderBy'
import { remoteSelectMixin } from '@hypefactors/shared/js/mixins/remoteSelectMixin'

/**
 * @module ClippingCountriesPicker
 */
export default {
  name: 'ClippingCountriesPicker',

  extends: remoteSelectMixin,

  methods: {
    search () {
      return this.$api.get('/countries')
        .then(response => response.data.data)
        .then(data => {
          return _orderBy(data, 'name')
        })
    }
  }
}
</script>
