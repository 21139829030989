<template>
  <page-section
    v-loading="form.isLoading"
    :page-title="$t('pages.coverage.clipping_edit.sidebar_title')"
  >
    <template #tooltip-content>
      <p class="m-b-s">
        {{ $t('pages.coverage.clipping_edit.sidebar_description_1') }}
      </p>
      <p class="m-b-s">
        {{ $t('pages.coverage.clipping_edit.sidebar_description_2') }}
      </p>
      <p class="m-b-s">
        {{ $t('pages.coverage.clipping_edit.sidebar_description_3') }}
      </p>
    </template>
    <div
      v-if="type"
      class="Add-clipping__create"
    >
      <div class="columns is-multiline">
        <div class="column is-6">
          <template v-if="type === 'print'">
            <label class="label">
              {{ $t('pages.coverage.clipping_edit.print_preview') }}
            </label>
            <clipping-print-area-select
              :files.sync="form.files"
              :size.sync="form.size"
            />
          </template>
          <template v-else-if="hasVideo">
            <label class="label">
              {{ $t('pages.coverage.clipping_edit.video_preview') }}
            </label>
            <video
              :src="firstFileUrl"
              class="video-preview"
              controls
              @durationchange="updateDurationOnElementLoad"
            />
          </template>
          <template v-else-if="hasAudio">
            <label class="label">
              {{ $t('pages.coverage.clipping_edit.audio_preview') }}
            </label>
            <audio
              :src="firstFileUrl"
              class="is-full-width audio-preview"
              controls
              @durationchange="updateDurationOnElementLoad"
            />
          </template>
          <template v-else>
            <label class="label">
              {{ $t('pages.coverage.clipping_edit.image_preview') }}
            </label>
            <div class="image">
              <img
                :src="fallbackImage"
                :alt="$t('general.screenshot')"
                class="image-preview"
                data-testid="ClippingImagePreview"
              >
            </div>
          </template>
        </div>
        <div class="column is-6">
          <form @submit.prevent="submit">
            <form-field
              v-if="canHaveHeadline"
              :label="$t('pages.coverage.clipping_edit.headline')"
              :validator="$v.form.headline"
            >
              <input
                v-model="form.headline"
                :placeholder="$t('pages.coverage.clipping_edit.fill_headline')"
                class="input"
                name="clipping_headline"
                type="text"
              >
            </form-field>
            <form-field
              v-if="canHaveHeadline"
              :label="$t('pages.coverage.clipping_edit.headline')"
              :validator="$v.form.headline"
            >
              <input
                v-model="form.translated_headline"
                :placeholder="$t('pages.coverage.clipping_edit.fill_translated_headline')"
                class="input"
                name="clipping_translated_headline"
                type="text"
              >
            </form-field>
            <form-field
              v-if="canHaveDuration"
              :label="$t('pages.coverage.clipping_edit.duration_of_recording')"
              :validator="$v.form.duration"
            >
              <input
                v-model.number="form.duration"
                :placeholder="$t('pages.coverage.clipping_edit.fill_in_duration')"
                class="input"
                type="text"
                name="duration"
                @input="handleDurationChange"
              >
            </form-field>
            <form-field
              :label="$t('general.published_at')"
              :validator="$v.form.published_at"
            >
              <el-date-picker
                v-model="form.published_at"
                :placeholder="$t('pages.coverage.clipping_edit.pick_day')"
                type="date"
                name="published_at"
                format="dd MMMM yyyy"
                data-testid="PublishedAtPicker"
              />
            </form-field>

            <div class="columns">
              <div class="column is-12">
                <form-field
                  :label="$t('general.media_outlet')"
                  :validator="$v.form.outlet"
                  class="mediaOutletField m-b-none"
                >
                  <clipping-media-outlets-picker
                    v-model="form.outlet"
                    :type="type"
                  />
                </form-field>
              </div>
            </div>

            <div class="columns">
              <div class="column is-12">
                <form-field
                  :label="$t('general.country')"
                  :validator="$v.form.country_code"
                  class="countryField m-b-none"
                >
                  <clipping-countries-picker
                    v-model="form.country_code"
                  />
                </form-field>
              </div>
            </div>

            <form-field :label="$t('general.topic')">
              <el-select
                v-model="form.topic"
                :placeholder="$t('forms.select')"
                name="topic"
              >
                <el-option
                  v-for="topic in topics"
                  :key="topic"
                  :label="$t('pages.coverage.clipping_edit.topic.' + topic)"
                  :value="topic"
                />
              </el-select>
            </form-field>

            <form-field
              :label="$t('pages.coverage.clipping_edit.is_product_present')"
              class="is-horizontal"
            >
              <el-switch
                v-model="form.is_product_present"
                name="product-present"
              />
            </form-field>

            <form-field
              :label="$t('pages.coverage.clipping_edit.is_logo_present')"
              class="is-horizontal"
            >
              <el-switch
                v-model="form.is_branding_present"
                name="logo-present"
              />
            </form-field>

            <form-field
              :label="$t('pages.coverage.clipping_edit.is_spokes_person')"
              class="is-horizontal"
            >
              <el-switch
                v-model="form.is_spokesperson_quoted"
                name="quoted"
              />
            </form-field>

            <form-field :label="$t('pages.coverage.clipping_edit.how_is_tonality')">
              <el-radio-group
                v-model="form.text_rating"
                name="text_rating"
                class="has-background-grey-lighter p-a-s is-flex is-aligned-justify"
              >
                <el-radio
                  v-for="(index, rating) in sentimentMapping"
                  :key="rating"
                  :label="index"
                  class="is-vertical"
                >
                  {{ $t('pages.coverage.rating.' + rating) }}
                </el-radio>
              </el-radio-group>
            </form-field>

            <form-field :label="$t('pages.coverage.clipping_edit.how_positive_are_images')">
              <el-radio-group
                v-model="form.picture_rating"
                name="picture_rating"
                class="has-background-grey-lighter p-a-s is-flex is-aligned-justify is-vertical"
              >
                <el-radio
                  v-for="(index, rating) in sentimentMapping"
                  :key="rating"
                  :label="index"
                  class="is-vertical"
                >
                  {{ $t('pages.coverage.rating.' + rating) }}
                </el-radio>
              </el-radio-group>
            </form-field>

            <v-button
              type="submit"
              class="Clipping--next is-primary pull-right"
            >
              {{ $t('general.next') }} <i class="hf hf-caret-right hf-fw" />
            </v-button>
          </form>
        </div>
      </div>
    </div>
  </page-section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import format from 'date-fns/format'
import Form from '@/services/forms/Form'
import { round } from '@hypefactors/shared/js/utils/numberUtils'
import { required, requiredIf, numeric } from 'vuelidate/lib/validators'

import ClippingPrintAreaSelect from '@/components/clippings/ClippingPrintAreaSelect'
import ClippingMediaOutletsPicker from '@/components/clippings/ClippingMediaOutletsPicker'
import ClippingCountriesPicker from '@/components/clippings/ClippingCountriesPicker'
import { SENTIMENT_SLUG_TO_NUMBER } from '@hypefactors/shared/js/constants/sentimentTypes'

const newClippingForm = () => new Form({
  topic: { value: 'mentioned' },
  type: { value: '' },
  is_product_present: { value: false },
  is_branding_present: { value: false },
  is_spokesperson_quoted: { value: false },
  text_rating: { value: 3 },
  picture_rating: { value: 3 },
  brand: { value: null },
  outlet: { value: null, rules: { required } },
  country_code: { value: null, rules: { required } },
  published_at: { value: null, rules: { required } },
  headline: {
    value: null,
    rules: {
      required: requiredIf(function () {
        return this.canHaveHeadline
      })
    }
  },
  translated_headline: { value: null },
  duration: {
    value: null,
    rules: {
      required: requiredIf(function () {
        return this.canHaveDuration
      }),
      numeric
    }
  },
  size: { value: 0 },
  files: { value: [] }
})

export default {
  components: {
    ClippingMediaOutletsPicker,
    ClippingCountriesPicker,
    ClippingPrintAreaSelect
  },

  props: {
    clippingId: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    form: newClippingForm(),
    cancelToken: null
  }),

  computed: {
    ...mapGetters('clippings/add', {
      type: 'type',
      headline: 'headline',
      translated_headline: 'translatedHeadline',
      duration: 'duration',
      thumbnail: 'thumbnail',
      files: 'files',
      publishedAt: 'publishedAt',
      suggestedMediaOutletId: 'suggestedMediaOutletId',
      suggestedCountryId: 'suggestedCountryId',
      hasVideo: 'hasVideo',
      hasAudio: 'hasAudio',
      preparedClipping: 'preparedClipping',
      preparedData: 'preparedData'
    }),

    sentimentMapping: () => SENTIMENT_SLUG_TO_NUMBER,

    topics: () => ['exclusive', 'secondary', 'mentioned'],

    isNewClipping () {
      return this.clippingId === ''
    },

    canHaveHeadline () {
      return !['instagram', 'twitter', 'facebook', 'linkedin', 'tiktok'].includes(this.type)
    },

    canHaveDuration () {
      return ['radio', 'tv'].includes(this.type)
    },

    firstFileUrl () {
      return this.$safeGet(this.files, '0.url', '')
    },

    fallbackImage () {
      return this.thumbnail || this.firstFileUrl || this.$asset('/images/general/no-img-placeholder.png')
    }
  },

  created () {
    this.prepareForEdit()
  },

  beforeDestroy () {
    this.cancelToken && this.cancelToken.cancel()
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  methods: {
    ...mapActions('clippings/add/', {
      fetchClipping: 'fetchClipping',
      createClippingAction: 'createClipping',
      updateClippingAction: 'updateClipping',
      resetClipping: 'reset'
    }),

    async prepareForEdit () {
      try {
        // if there is no preparedClipping and its a new clipping, error out
        if (!this.preparedClipping && this.isNewClipping) {
          this.$notify.error({
            title: this.$t('errors.error'),
            duration: 5000,
            message: this.$t('errors.cannot_access_clipping_directly')
          })
          return this.$router.go(-1)
        }

        // If preparedClipping is not available and is not a new clipping, we come from a back action or page refresh
        if (!this.preparedClipping && !this.isNewClipping) {
          this.cancelToken && this.cancelToken.cancel()
          this.cancelToken = this.$api.cancelToken()
          await this.fetchClipping({ id: this.clippingId, cancelToken: this.cancelToken.token })
        }

        // prepare the form from Vuex dedicated getters
        const clippingFormData = {
          headline: this.headline,
          translated_headline: this.translated_headline,
          published_at: this.publishedAt,
          outlet: this.suggestedMediaOutletId,
          country_code: this.suggestedCountryId,
          type: this.type,
          files: this.files,
          duration: this.duration
        }

        const itemsToExtract = ['topic', 'is_product_present', 'is_branding_present', 'is_spokesperson_quoted', 'size', 'text_rating', 'picture_rating']
        itemsToExtract.forEach(prop => {
          clippingFormData[prop] = this.preparedClipping[prop]
        })

        this.form.merge(clippingFormData)
      } catch (err) {
        if (this.$api.isCancelToken(err)) return

        this.$displayRequestError(err)
      }
    },

    submit () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      this.submitAction()
        .then((clippingResponse) => {
          this.$router.push({
            name: 'performance.coverage.clipping.assign',
            params: {
              clippingId: clippingResponse.id
            }
          })
        })
        .catch(err => {
          this.$displayRequestError(err, undefined, undefined, [400])
        })
    },

    /**
     * Determines if it should fire a Create or Update action
     * @return {Promise<any>}
     */
    submitAction () {
      return this.isNewClipping
        ? this._createClipping()
        : this._updateClipping()
    },

    _createClipping () {
      this.form.setPayloadTransformer(payload => {
        const clipping = {
          ...this.preparedClipping, // has the `data` as well
          ...payload // overwrite with the changed fields on the form
        }

        if (this.canHaveHeadline) {
          // TODO: Check if other fields need to be added data, currently only headline is sure
          clipping.data.headline = clipping.headline
          clipping.data.translated_headline = clipping.translated_headline
        }

        // TODO: Check what other fields need to be pushed to data, as there is no docs
        if (this.canHaveDuration) {
          clipping.data.duration_sec = payload.duration
        }

        clipping.published_at = format(clipping.published_at, 'X')
        clipping.brand = this.activeBrandId

        return clipping
      })
      return this.createClippingAction(this.form)
    },

    _updateClipping () {
      this.form.setPayloadTransformer(payload => {
        payload.brand = this.activeBrandId
        // TODO: check if other clipping types need fields other than "headline". We try to pass data if at some point it exists in the payload
        payload.data = {
          ...this.preparedData,
          headline: payload.headline,
          translated_headline: payload.translated_headline,
          duration_sec: payload.duration
        }
        payload.published_at = format(payload.published_at, 'X')
        return payload
      })
      return this.updateClippingAction(this.form)
    },

    updateDurationOnElementLoad (e) {
      const duration = this.$safeGet(e, 'target.duration', 0)
      this.form.duration = Math.round(duration)
      this.handleDurationChange()
    },

    handleDurationChange () {
      this.$v.form.duration.$touch()
      if (!this.canHaveDuration) return
      this.form.size = round(parseInt(this.form.duration) / 60)
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.Add-clipping__create {
  form {
    margin-top: 0;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }

  .el-input__icon {
    line-height: 2rem;
  }
}
</style>
