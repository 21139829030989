<template>
  <div class="ClippingPrintAreaSelect">
    <div class="ClippingPrintAreaSelect__FilesList">
      <el-carousel
        :autoplay="false"
        trigger="click"
        height="500px"
        indicator-position="outside"
      >
        <el-carousel-item
          v-for="(file, index) in files"
          :key="index"
        >
          <ClippingPrintAreaSelectFile
            :file="file.url"
            :index="index"
            :crop-data="file.cropData"
            :data-elementIndex="index"
            @crop="handleAreaSelectChange"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import ClippingPrintAreaSelectFile from '@/components/clippings/ClippingPrintAreaSelectFile'

export default {
  components: {
    ClippingPrintAreaSelectFile
  },
  props: {
    // All the files for current clipping
    files: {
      type: Array,
      required: true
    },
    // The size for the current clipping
    size: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      // cache crop data for next time
      // cropData: this.files.map(f => f.meta),
      // cache the imageData for each file
      // imageData: [],
      // cached cropped area sizes
      sizes: this.files.map(size => 1)
    }
  },
  mounted () {
    this.files.forEach((f, i) => this.recalculateSizeForIndex(i, false))
  },
  methods: {
    /**
     *  On each crop/area change we save the data and recalculate the area percentage
     */
    handleAreaSelectChange ({ index, cropData, imageData }) {
      // use proper event
      this.updateFilesProp(index, { cropData, imageData })
      this.recalculateSizeForIndex(index, true)
    },
    /**
     * Recalculates the size prop
     * @param {Number} index - index at which to update at
     * @param {Boolean} shouldSumSizes - Sums up the sizes and updates the parent prop
     */
    recalculateSizeForIndex (index, shouldSumSizes = true) {
      // get data
      const imgData = this.files[index].imageData
      const cropData = this.files[index].cropData
      // if not existent just return
      if (!imgData || !cropData) return
      // calculate areas
      const imgArea = imgData.naturalWidth * imgData.naturalHeight
      const cropArea = cropData.width * cropData.height
      const percentageCovered = cropArea / imgArea
      // cache areas
      this.sizes[index] = Math.round(percentageCovered * 100) / 100
      // sum them up
      if (shouldSumSizes) this.sumSizes()
    },
    /**
     * Sums up all the file area selection sizes
     */
    sumSizes () {
      // sum up all sizes
      const size = this.sizes.reduce((all, current) => all + current, 0)
      // emit to parent
      this.$emit('update:size', size)
    },
    updateFilesProp (index, { cropData, imageData }) {
      // Clone the files
      const cachedFiles = this.files.slice()
      // If we have imageData don't overwrite it
      if (!cachedFiles[index].imageData) cachedFiles[index].imageData = imageData
      // update cropData
      cachedFiles[index].cropData = cropData
      // update passed files prop
      this.$emit('update:files', cachedFiles)
    }
  }
}
</script>
